import { useContext } from 'react'
import { Context } from './index'
const useStore = () => {
  const [state, dispatch] = useContext(Context)
  const openSideBar = () => {
    dispatch({ type: 'OPEN_SIDEBAR' })
  }
  const closeSideBar = () => {
    dispatch({ type: 'CLOSE_SIDEBAR' })
  }
  const toggleSidebar = () => {
    dispatch({ type: 'TOGGLE_SIDEBAR' })
  }
  const listImages = () => {
    return state.images
  }
  const getImageByName = (imgName) => {
    return state.images.find(({ parent }) => parent.name === imgName)
  }
  const getImagesByName = (imageNames) => {
    const images = []
    imageNames.forEach((imgName) => {
      const img = getImageByName(imgName)
      if (img) {
        images.push(img)
      }
    })
    return images
  }
  const getImagesByFolderName = (folderName) => {
    return state.images.filter(({ parent }) => parent.relativeDirectory === folderName)
  }
  return {
    state,
    openSideBar,
    closeSideBar,
    toggleSidebar,
    listImages,
    getImageByName,
    getImagesByName,
    getImagesByFolder: getImagesByFolderName,
  }
}
export default useStore
